import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'reactstrap'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`church`, `bronx`, `afcm`, `ambassadors for christ`, `ambassadors`, `Christ`, `Jesus`]} />
    
    <Container>
      <section className='index-header-section'>
      <Row>
        <Col md={{size: 8, offset: 2}}>
          <h1 className='index-header'>A church that helps you connect to God and transform your life</h1>
        </Col>
      </Row>
      </section>
      <section>
        <Row>
          <HomeBox title='VISIT' url='/visit' />
          <HomeBox title={`WATCH${'\n'}SERMONS`} url='/sermons' />
          <HomeBoxExternal title='DONATE' url='https://afcm.churchcenter.com/giving' />
        </Row>
      </section>
    </Container>
  </Layout>
)

const HomeBox = ({title, url}) => (
  <Col md={{size: 4}} sm={{size: 12}} style={{marginBottom: 20}}>
    <Link to={url}>
      <div className='home-box'>
        <p style={{whiteSpace: 'pre'}}>{title}</p>
      </div>
    </Link>
  </Col>
)

const HomeBoxExternal = ({title, url}) => (
  <Col md={{size: 4}} sm={{size: 12}} style={{marginBottom: 20}}>
    <a href={url}>
      <div className='home-box'>
        <p style={{whiteSpace: 'pre'}}>{title}</p>
      </div>
    </a>
  </Col>
)

export default IndexPage
